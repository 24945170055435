import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
  import VueExcelXlsx from "vue-excel-xlsx";

// import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'



// Axios Mock Adapter
// Axios Mock Adapter

// import '@/@fake-db/db'
import { ValidationObserver, ValidationProvider} from 'vee-validate';
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Vue.use(vueCountryRegionSelect)

// Composition API
Vue.use(VueCompositionAPI)
  Vue.use(VueExcelXlsx);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = true
// const eventHub = new Vue() // Single event hub

//  // Distribute to components using global mixin
//  Vue.mixin({
//      data: function () {
//          return {
//              eventHub: eventHub
//          }
//      }
//  })
new Vue({
  router,
  store,
  // i18n,
  render: h => h(App),
}).$mount('#app')
