export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/apps/add-beneficiary/',
    name: 'add-beneficiary',
    component: () => import('@/views/apps/add-beneficiary/family-file/add-family.vue'),
    meta: {

      resource: 'beneficiaries',
      action: 'read',
    },
  },
  {
    path: '/apps/complains/add-complains',
    name: 'add-my-problem',
    component: () => import('@/views/apps/problems/add-problem.vue'),
    meta:{
      resource: 'Auth',
      action: 'read',
          }
  },
  {
    path: '/apps/services/services-list',
    name: 'service-list',
    component: () => import('@/views/apps/services-beneficiary-list/ServicesList.vue'),
    meta: {

      resource: 'services',
      action: 'read',
    },
  },
  {
    path: '/apps/services/services-families-list',
    name: 'services-families-list',
    component: () => import('@/views/apps/services-families-list/ServicesList.vue'),
    meta: {

      resource: 'services',
      action: 'read',
    },
  },
  {
    path: '/page-layouts/layout-blank',
    name: 'page-layout-layout-blank',
    component: () => import('@/views/ui/page-layouts/LayoutBlank.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Layout Blank',
    }},
  {
    path: '/',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action:'read',
      
    },
  },
  {
    path: '/pages/authentication/login-v1',
    name: 'auth-login-v1',
    component: () => import('@/views/pages/authentication/Login-v1.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/authentication/login-v2',
    name: 'auth-login-v2',
    component: () => import('@/views/pages/authentication/Login-v2.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pages/authentication/register-v1',
    name: 'auth-register-v1',
    component: () => import('@/views/pages/authentication/Register-v1.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/authentication/register-v2',
    name: 'auth-register-v2',
    component: () => import('@/views/pages/authentication/Register-v2.vue'),
    meta: {
      layout: 'full',
    },
  },

  {
    path: '/pages/authentication/forgot-password-v1',
    name: 'auth-forgot-password-v1',
    component: () => import('@/views/pages/authentication/ForgotPassword-v1.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/authentication/forgot-password-v2',
    name: 'auth-forgot-password-v2',
    component: () => import('@/views/pages/authentication/ForgotPassword-v2.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/authentication/reset-password-v1',
    name: 'auth-reset-password-v1',
    component: () => import('@/views/pages/authentication/ResetPassword-v1.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/authentication/reset-password-v2',
    name: 'auth-reset-password-v2',
    component: () => import('@/views/pages/authentication/ResetPassword-v2.vue'),
    meta: {
      layout: 'full',
    },
  }

]
