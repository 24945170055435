export default [
 
  {
    path: '/apps/department',
    name: 'department',
    component: () => import('@/views/apps/department/add-department.vue'),
  },
  {
    path: '/apps/report-weakness',
    name: 'generate-report',
    component: () => import('@/views/apps/GenerateReport.vue'),
    meta: {
      resource: 'modification_requests',
      action: 'read',
    },
  },
  {
    path: '/apps/enter-warehouse',
    name: 'enter-warehouse',
    component: () => import('@/views/apps/finance/warehouse-transaction/Enter-warehouse.vue'),
    meta: {
      resource: 'warehouse_transactions',
      action: 'all',
    },
  },
  {
    path: '/apps/transaction-archive',
    name: 'transaction-archive',
    component: () => import('@/views/apps/finance/warehouse-transaction/transaction-archive.vue'),
    meta: {
      resource: 'warehouse_transactions',
      action: 'all',
    },
  },
  {
    path: '/apps/withdrawal-warehouse',
    name: 'withdrawal-warehouse',
    component: () => import('@/views/apps/finance/warehouse-transaction/withdrawal-warehouse.vue'),
    meta: {
      resource: 'warehouse_transactions',
      action: 'all',
    },
  },
  {
    path: '/apps/return-note-warehouse',
    name: 'return-note-warehouse',
    component: () => import('@/views/apps/finance/warehouse-transaction/return-note-warehouse.vue'),
    meta: {
      resource: 'warehouse_transactions',
      action: 'all',
    },
  },
  {
    path: '/apps/disposal-note-warehouse',
    name: 'disposal-note-warehouse',
    component: () => import('@/views/apps/finance/warehouse-transaction/disposal-note-warehouse.vue'),
    meta: {
      resource: 'warehouse_transactions',
      action: 'all',
    },
  }, {
    path: '/apps/first-enter-warehouse',
    name: 'first-enter-warehouse',
    component: () => import('@/views/apps/finance/warehouse-transaction/first-enter-warehouse.vue'),
    meta: {
      resource: 'warehouse_transactions',
      action: 'all',
    },
  },
  {

    path: '/apps/all-benificary',
    name: 'all-benificary',
    component: () => import('@/views/apps/allBenficiaryForRoles/List.vue'),
    meta: {
      resource: 'all_benificary',
      action: 'all',
    },
  },
  {
    path: '/apps/show-beneficiary-info/:id',
    name: 'show-beneficiary-info',
    component: () => import('@/views/apps/allBenficiaryForRoles/showBen/show-beneficiary.vue'),
    meta: {
      action: 'all',
      resource: 'all_benificary',
    },
  },
  {
    path: '/apps/finance-accounts',
    name: 'finance-accounts',
    component: () => import('@/views/apps/account-guide/add-account.vue'),
    meta: {
      resource: 'finance_accounts',
      action: 'all',
    },
  },

  {
    path: '/apps/finance-accounts/:id',
    name: 'finance-accounts-id',
    component: () => import('@/views/apps/account-guide/edit-account.vue'),
    meta: {
      resource: 'finance_accounts',
      action: 'all',
    },
  },
  /// ////////////// Report details

  {
    path: '/apps/delivery-reports',
    name: 'delivery-reports',
    component: () => import('@/views/apps/finance/delivery-reports/List.vue'),
    meta: {
      resource: 'receipt_report',
      action: 'all',
    },
  },
  /// ////////////// archives

  {
    path: '/apps/transactions-archive',
    name: 'transactions-archive',
    component: () => import('@/views/apps/transactions-archive/archiveList.vue'),
    meta: {
      resource: 'transactions_archive',
      action: 'all',
    },
  },
  {
    path: '/apps/transactions-archive/:id',
    name: 'transactions-archive-id',
    component: () => import('@/views/apps/transactions-archive/show-archive.vue'),
    meta: {
      resource: 'transactions_archive',
      action: 'all',
    },
  },
  {
    path: '/apps/edit-transactions-archive/:id',
    name: 'edit-transactions-archive-id',
    component: () => import('@/views/apps/transactions-archive/edit-archive.vue'),
    meta: {
      resource: 'transactions_archive',
      action: 'all',
    },
  },
  /// ////////////// warehouse

  {
    path: '/apps/warehouse-details',
    name: 'warehouse-details',
    component: () => import('@/views/apps/warehouse-details/warehouseList.vue'),
    meta: {
      resource: 'warehouse_details',
      action: 'all',
    },
  },
  {
    path: '/apps/general-warehouse-details',
    name: 'general-warehouse-details',
    component: () => import('@/views/apps/warehouse-details/warehouseList.vue'),
    meta: {
      resource: 'warehouse_details',
      action: 'all',
    },
  },
  {
    path: '/apps/warehouse-details/:id',
    name: 'warehouse-details-id',
    component: () => import('@/views/apps/warehouse-details/edit-warehouse.vue'),
    meta: {
      resource: 'warehouse_details',
      action: 'all',
    },
  },
  {
    path: '/apps/general-warehouse-details/:id',
    name: 'general-warehouse-details-id',
    component: () => import('@/views/apps/warehouse-details/edit-general-warehouse.vue'),
    meta: {
      resource: 'warehouse_details',
      action: 'all',
    },
  },
  /// ////////////// purches Order

  {
    path: '/apps/purches-order',
    name: 'purches-order-approve',
    component: () => import('@/views/apps/finance/purchase-order-approve/purchesList.vue'),
    meta: {
      resource: 'purchase_order',
      action: 'all',
    },
  },
  {
    path: '/apps/purches-order/:id',
    name: 'purches-order-approve-id',
    component: () => import('@/views/apps/finance/purchase-order-approve/edit-purches.vue'),
    meta: {
      resource: 'purchase_order',
      action: 'all',
    },
  },
  {
    path: '/apps/purches-request',
    name: 'purches-request-approve',
    component: () => import('@/views/apps/finance/purchase-request-approve/purchesList.vue'),
    meta: {
      resource: 'in_transaction',
      action: 'all',
    },
  },
  {
    path: '/apps/purches-request/:id',
    name: 'purches-request-approve-id',
    component: () => import('@/views/apps/finance/purchase-request-approve/edit-purches.vue'),
    meta: {
      resource: 'in_transaction',
      action: 'all',
    },
  },
  {
    path: '/apps/complete-procurment-order',
    name: 'complete-procurment-order',
    component: () => import('@/views/apps/finance/complete-procurment-order/List.vue'),
    meta: {
      resource: 'complete_procurment_order',
      action: 'all',
    },
  },
  {
    path: '/apps/follow-up-purchase-request',
    name: 'follow-up-purchase-request',
    component: () => import('@/views/apps/finance/follow-up-purchase-request.vue'),
    meta: {
      resource: 'follow_up_purchase_request',
      action: 'all',
    },
  },
  {
    path: '/apps/complete-procurment-order/:id',
    name: 'complete-procurment-order-id',
    component: () => import('@/views/apps/finance/complete-procurment-order/edit.vue'),
    meta: {
      resource: 'complete_procurment_order',
      action: 'all',
    },
  },

  {
    path: '/apps/complete-out-transaction',
    name: 'complete-out-transaction',
    component: () => import('@/views/apps/finance/complete-out-transaction/List.vue'),
    meta: {
      resource: 'complete_out_transaction',
      action: 'all',
    },
  },
  {
    path: '/apps/complete-out-transaction/:id',
    name: 'complete-out-transaction-id',
    component: () => import('@/views/apps/finance/complete-out-transaction/edit.vue'),
    meta: {
      resource: 'complete_out_transaction',
      action: 'all',
    },
  },

  {
    path: '/apps/complete-purches-order',
    name: 'complete-purches-order',
    component: () => import('@/views/apps/finance/complete-purches-order/List.vue'),
    meta: {
      resource: 'complete_purches_order',
      action: 'all',
    },
  },
  {
    path: '/apps/item-expiration-date',
    name: 'item-expiration-date',
    component: () => import('@/views/apps/finance/Expiration-date.vue'),
    meta: {
      resource: 'item_expiration_date',
      action: 'all',
    },
  },
  {
    path: '/apps/complete-purches-order/:id',
    name: 'complete-purches-order-id',
    component: () => import('@/views/apps/finance/complete-purches-order/edit.vue'),
    meta: {
      resource: 'complete_purches_order',
      action: 'all',
    },
  },
  {
    path: '/apps/complete-payment-order',
    name: 'payment-order',
    component: () => import('@/views/apps/finance/payment-order/List.vue'),
    meta: {
      resource: 'prepare_exchange_order',
      action: 'all',
    },
  },
  {
    path: '/apps/finance-payment-order',
    name: 'payment-order-finance',
    component: () => import('@/views/apps/finance/payment-order-finance/List.vue'),
    meta: {
      resource: 'approve_exchange_order',
      action: 'all',
    },
  },
  {
    path: '/apps/add-position',
    name: 'position',
    component: () => import('@/views/apps/position/add-position.vue'),
    meta: {
      resource: 'position',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- Families -------------------------------*
  {
    path: '/apps/manageServer',
    name: 'manageSite',
    component: () => import('@/views/apps/manage.vue'),
    meta: {
      resource: 'modification_requests',
      action: 'read',
    },
  },
  {
    path: '/apps/un-completed-families',
    name: 'un-completed-families',
    component: () => import('@/views/apps/unCompletedFamilies/FamiliesList.vue'),
    meta: {
      resource: 'beneficiaries',
      action: 'read',
    },
  },
  {
    path: '/apps/families-uncompleted/:id',
    name: 'families-uncompleted',
    component: () => import('@/views/apps/unCompletedFamilies/Edit-Family-Uncompleted.vue'),
    meta: {
      resource: 'beneficiaries',
      action: 'read',
    },
  },
  {
    path: '/apps/families-Beneficiaries-uncompleted/:id',
    name: 'families-Beneficiaries-uncompleted',
    component: () => import('@/views/apps/unCompletedFamilies/FamiliesBenefList.vue'),
    meta: {
      resource: 'beneficiaries',
      action: 'read',
    },
  },
  {
    path: '/apps/modification-requests',
    name: 'modification-requests',
    component: () => import('@/views/apps/families/required-modifications/modificationsList.vue'),
    meta: {
      resource: 'modification_requests',
      action: 'read',
    },
  },
  {
    path: '/apps/additional-requests',
    name: 'additional-requests',
    component: () => import('@/views/apps/families/required-modifications/additionList.vue'),
    meta: {
      resource: 'modification_requests',
    },
  },
  {
    path: '/apps/pending-transaction/:id',
    name: 'show-trans-addition',
    component: () => import('@/views/apps/pending-transaction/show-transactionList.vue'),
    meta: {
      resource: 'pending_transaction_archive',
      action: 'all',
    },
  },
  {
    path: '/apps/pending-transaction',
    name: 'pending-transaction',
    component: () => import('@/views/apps/pending-transaction/transactionList.vue'),
    meta: {
      resource: 'pending_transaction_archive',
      action: 'all',
    },
  },
  {
    path: '/apps/show-addition/:id',
    name: 'show-addition',
    component: () => import('@/views/apps/families/required-modifications/show-addition.vue'),
    meta: {
      resource: 'modification_requests',
    },
  },
  {
    path: '/apps/Allfamilies',
    name: 'all-families',
    component: () => import('@/views/apps/families/all-families/FamiliesList.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },
  {
    path: '/apps/families-beneficiaries/:id',
    name: 'families-Beneficiaries',
    component: () => import('@/views/apps/families/family-beneficiaries/FamiliesBenefList.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },
  {
    path: '/apps/edit-families-details/:id',
    name: 'edit-families-details',
    component: () => import('@/views/apps/families/family-beneficiaries/Edit-Family-Details.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },

  // {
  //   path: '/apps/Assessment-Form',
  //   name: 'Assessment-Form',
  //   component: () => import('@/views/apps/Assessment-Form/assessment-form.vue'),
  // },
  // {
  //   path: '/apps/Assessment-Form',
  //   name: 'Assessment-Form',
  //   component: () => import('@/views/apps/Assessment-Form/assessment-form.vue'),
  // },

  {
    path: '/apps/Allbeneficiaries/edit-beneficiary/:id',
    name: 'edit-beneficiary',
    component: () => import('@/views/apps/families/family-beneficiaries/edit-beneficiary/edit-beneficiary.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },
  {
    path: '/apps/Allbeneficiaries/show-beneficiary/:id',
    name: 'show-beneficiary',

    component: () => import('@/views/apps/families/family-beneficiaries/show-benficiary/show-beneficiary.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },

  },
  {
    path: '/apps/Allbeneficiaries/show-beneficiary/:id',
    name: 'show-beneficiary-services',

    component: () => import('@/views/apps/families/family-beneficiaries/show-benficiary/show-beneficiary.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },

  },
  // {
  //   path: '/apps/Assessment-Form/:id',
  //   name: 'edit',
  //   component: () => import('@/views/apps/Allbeneficiaries/edit.vue'),
  // },
  // {
  //   path: '/apps/Assessment-Form/medical-referral',
  //   name: 'medical-referral',
  //   component: () => import('@/views/apps/Assessment-Form/medical-referral/medical-referral.vue'),
  // },
  // {
  //   path: '/apps/Assessment-aid/',
  //   name: 'Assessment-aid',
  //   component: () => import('@/views/apps/Assessment-aid/assessment-aid.vue'),
  // },
  // {
  //   path: '/apps/psychosocial-support/',
  //   name: 'psychosocial-support',
  //   component: () => import('@/views/apps/psychosocial-support/psychosocial-support.vue'),
  // },
  // {
  //   path: '/apps/Incident-Recorder/',
  //   name: 'incident-recorder',
  //   component: () => import('@/views/apps/Incident-Recorder/incident-recorder.vue'),
  // },
  // {
  //   path: '/apps/Survivor-plan/',
  //   name: 'Survivor-plan',
  //   component: () => import('@/views/apps/Survivor-plan/Survivor-plan.vue'),
  // },
  // {
  //   path: '/apps/Case-follow-up/',
  //   name: 'Case-follow-up',
  //   component: () => import('@/views/apps/Case-follow-up/case-follow.vue'),
  // },
  // {
  //   path: '/apps/case-close-up/',
  //   name: 'case-close-up',
  //   component: () => import('@/views/apps/case-close-up/case-close.vue'),
  // },
  // {
  //   path: '/apps/Consent-Form/',
  //   name: 'Consent-Form',
  //   component: () => import('@/views/apps/Consent-Form/consent-form.vue'),
  // },
  // {
  //   path: '/apps/Referral-Form/',
  //   name: 'Referral-Form',
  //   component: () => import('@/views/apps/Referral-Form/referral-form.vue'),
  // },
  // {
  //   path: '/apps/monitoring-paper/',
  //   name: 'monitoring-paper',
  //   component: () => import('@/views/apps/monitoring-paper/monitoring-paper.vue'),
  // },
  // {
  //   path: '/apps/educationl-programs/',
  //   name: 'educationl-programs',
  //   component: () => import('@/views/apps/educationl-programs/educationl-programs.vue'),
  // },
  // {
  //   path: '/apps/evaluation-questionnaire/',
  //   name: 'evaluation-question',
  //   component: () => import('@/views/apps/evaluation-questionnaire/evaluation-question.vue'),
  // },
  // {
  //   path: '/apps/emergency',
  //   name: 'emergency',
  //   component: () => import('@/views/apps/emergency/emergency.vue'),
  // },

  {
    path: '/apps/add-beneficiary/',
    name: 'add-beneficiary',
    component: () => import('@/views/apps/add-beneficiary/family-file/FormWizard.vue'),
    meta: {
      // action:'read',
      resource: 'beneficiaries',
    },
  },
  //     Services

  {
    path: '/apps/services/service-report/:id',
    name: 'service-report',
    component: () => import('@/views/apps/services-beneficiary-list/service-report.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },
  },
  {
    path: '/apps/services/show-beneficiary-data/:id',
    name: 'show-beneficiary-data',
    component: () => import('@/views/apps/services-beneficiary-list/showBenServices/show-beneficiary.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },
  },
//show family information
  {
    path: '/apps/services/show-family-info/:id',
    name: 'show-family-data',
    component: () => import('@/views/apps/services-beneficiary-list/showBenServices/show-family-info.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },
  },
  {
    path: '/apps/services/service-committe-report/:id',
    name: 'service-committe-report',
    component: () => import('@/views/apps/services-beneficiary-list/committe-report.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },
  },
  {
    path: '/apps/services/service-transfer/:id',
    name: 'service-transfer',
    component: () => import('@/views/apps/services-beneficiary-list/service-transfer.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },
  },
  {
    path: '/apps/services/transfer-list',
    name: 'transfer-list',
    component: () => import('@/views/apps/ServiceTransfer/index.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },
  },
  {
    path: '/apps/ServiceListTransfer',
    name: 'ServiceListTransfer',
    component: () => import('@/views/apps/Services/ServiceList.vue'),
    meta: {
      resource: 'all',
      action: 'manage',
    },
  },
  {
    path: '/apps/services/show-service-report/:id',
    name: 'show-service-report',
    component: () => import('@/views/apps/services-beneficiary-list/show-service-report.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },
  },
  // Roles
  // {
  //   path: '/pages/miscellaneous/not-authorized',
  //   name: 'misc-not-authorized',
  //   component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
  //   meta: {
  //     layout: 'full',
  //     action: 'read',

  //   },

  // },
  {
    path: '/apps/roles/allroles',
    name: 'all-roles',
    component: () => import('@/views/apps/roles/allroles.vue'),
    meta: {
      action: 'read',
      resource: 'roles',
    },
  },
  {
    path: '/apps/roles/showPermission/:id',
    name: 'showPermission',
    component: () => import('@/views/apps/roles/showPermission.vue'),
    meta: {
      action: 'read',
      resource: 'roles',
    },
  },
  {
    path: '/apps/roles/addroles',
    name: 'addrole',
    component: () => import('@/views/apps/roles/addroles.vue'),
    meta: {
      action: 'read',
      resource: 'roles',
    },
  },
  /// //////////achievement_targets//////////////
  {
    path: '/apps/achievement_targets',
    name: 'achievement_targets',
    component: () => import('@/views/apps/achievement_targets/achievement_targets.vue'),
    meta: {
      action: 'all',
      resource: 'goals',
    },
  },
  {
    path: '/apps/edit-achievement_targets/:id',
    name: 'edit-achievement_targets',
    component: () => import('@/views/apps/achievement_targets/edit-achievement_targets.vue'),
    meta: {
      action: 'all',
      resource: 'goals',
    },
  },
  /// //////////pacts//////////////
  {
    path: '/apps/pacts',
    name: 'pacts',
    component: () => import('@/views/apps/pacts/add-user-pacts.vue'),
    meta: {
      action: 'all',
      resource: 'pacts',
    },
  },
  {
    path: '/apps/edit-pacts/:id',
    name: 'edit-pacts',
    component: () => import('@/views/apps/pacts/edit-user-pacts.vue'),
    meta: {
      action: 'all',
      resource: 'pacts',
    },
  },
   /// //////////volunteer//////////////
   {
    path: '/apps/volunteer',
    name: 'volunteer',
    component: () => import('@/views/apps/volunteer/add-volunteer.vue'),
    meta: {
      action: 'all',
      resource: 'store_visit',
    },
  },
  {
    path: '/apps/edit-volunteer/:id',
    name: 'edit-volunteer',
    component: () => import('@/views/apps/volunteer/edit-volunteer.vue'),
    meta: {
      action: 'all',
      resource: 'store_visit',
    },
  },
  {
    path: '/apps/all-policies',
    name: 'all-policies',
    component: () => import('@/views/apps/policy/show/List.vue'),
    meta: {
      action: 'all',
      resource: 'policy',
    },
  },
  {
    path: '/apps/policy/add-policy',
    name: 'add-policy',
    component: () => import('@/views/apps/policy/add-policy.vue'),
    meta: {
      action: 'create',
      resource: 'policy',
    },
  },
  {
    path: '/apps/edit-policy/:id',
    name: 'edit-policy',
    component: () => import('@/views/apps/policy/edit-policy.vue'),
    meta: {
      action: 'create',
      resource: 'policy',
    },
  },
  {
    path: '/apps/show-policy/:id',
    name: 'show-policy',
    component: () => import('@/views/apps/policy/show-policy.vue'),
    meta: {
      action: 'all',
      resource: 'policy',
    },
  },


   /// SESSION 
   //New Permission 
   {
    path: '/apps/session/add-session',
    name: 'add-session',
    component: () => import('@/views/apps/session/add-session.vue'),
    meta: {
      action: 'all',
      resource: 'session_mobile_app',
    },
  },
  {
    path: '/apps/session/all-session',
    name: 'all-session',
    component: () => import('@/views/apps/session/list/SessionList.vue'),
    meta: {
      action: 'all',
      resource: 'session_mobile_app',
    },
  },
  {
    path: '/apps/session/add-attendence/:id',
    name: 'add-attendence',
    component: () => import('@/views/apps/session/session-attendence/add-attendence.vue'),
    meta: {
      action: 'all',
      resource: 'session_mobile_app',
    },
  },
  {
    path: '/apps/session/show-session/:id',
    name: 'show-session',
    component: () => import('@/views/apps/session/show/AttendeceList.vue'),
    meta: {
      action: 'all',
      resource: 'session_mobile_app',
    },
  },
  /// LOCATION
  {
    path: '/apps/location/add-location',
    name: 'add-location',
    component: () => import('@/views/apps/location/add-location.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },
  {
    path: '/apps/CountryList',
    name: 'CountryList',
    component: () => import('@/views/apps/location/locations/CountryList.vue'),
    meta: {
      // action:'read',
      // resource:'beneficiaries'
    },
  },
  /// STATISTICS
  // registering-statistics
  {
    path: '/apps/registering-statistics',
    name: 'registering-statistics',
    component: () => import('@/views/apps/registering-statistics/index.vue'),
    meta: {
      resource: 'registration_statistics',
      action: 'all',
    },
  },
  {
    path: '/apps/statistics-of-beneficiaries',
    name: 'Statistics',
    component: () => import('@/views/apps/statistics-of-beneficiaries/statistics.vue'),
    meta: {
      resource: 'statistics',
      action: 'read',
    },
  },
  {
    path: '/apps/achievement_stats',
    name: 'achievement_stats',
    component: () => import('@/views/apps/achievement_stats/index.vue'),
    meta: {
      resource: 'goals',
      action: 'all',
    },
  },

  {
    path: '/apps/beneficiaries-list',
    name: 'beneficiaries-list',
    component: () => import('@/views/apps/advanced-statistics/beneficiary-list.vue'),
    meta: {
      resource: 'statistics',
      action: 'all',
    },
  },

  {
    path: '/apps/SubServiceList/:id',
    name: 'SubServiceList',
    component: () => import('@/views/apps/Services/SubServList.vue'),
    meta: {
      resource: 'all',
      action: 'manage',
    },
  },
  {
    path: '/apps/location/add-service',
    name: 'add-service',
    component: () => import('@/views/apps/Services/add-service.vue'),
    meta: {
      resource: 'all',
      action: 'manage',
    },
  },
  {
    path: '/apps/GovernateList/:id',
    name: 'GovernateList',
    component: () => import('@/views/apps/location/locations/GovernateList.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },
  {
    path: '/apps/CitiesList/:id',
    name: 'CitiesList',
    component: () => import('@/views/apps/location/locations/CitiesList.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },
  {
    path: '/apps/VillagesList/:id',
    name: 'VillagesList',
    component: () => import('@/views/apps/location/locations/VillagesList.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },
  // WORK
  {
    path: '/apps/add-work',
    name: 'add-work',
    component: () => import('@/views/apps/add-work/add-work.vue'),
    meta: {
      action: 'read',
      resource: 'works',
    },
  },
  {
    path: '/apps/work/edit-work/:id',
    name: 'edit-work',
    component: () => import('@/views/apps/add-work/edit-work.vue'),
    meta: {
      action: 'read',
      resource: 'works',
    },
  },
   // PERSON STATUS
   {
    path: '/apps/add-person-status',
    name: 'add-person-status',
    component: () => import('@/views/apps/add-person-status/add-person-status.vue'),
    meta: {
      resource: 'all',
      action: 'manage',
    },
  },
  {
    path: '/apps/person-status/edit-person-status/:id',
    name: 'edit-person-status',
    component: () => import('@/views/apps/add-person-status/edit-person-status.vue'),
    meta: {
      resource: 'all',
      action: 'manage',
    },
  },
  // DOCUMENTS
  {
    path: '/apps/add-document',
    name: 'add-document',
    component: () => import('@/views/apps/add-document/add-document.vue'),
    meta: {
      resource: 'all',
      action: 'manage',
    },
  },
  {
    path: '/apps/document/edit-document/:id',
    name: 'edit-document',
    component: () => import('@/views/apps/add-document/edit-document.vue'),
    meta: {
      resource: 'all',
      action: 'manage',
    },
  },
  // WEAKNESS
  {
    path: '/apps/add-weakpoint',
    name: 'add-weakpoint',
    component: () => import('@/views/apps/add-weakpoint/add-weakpoint.vue'),
    meta: {
      action: 'read',
      resource: 'weaknesses',
    },
  },
  {
    path: '/apps/edit-weakpoint/:id',
    name: 'edit-weakpoint',
    component: () => import('@/views/apps/add-weakpoint/edit-weakpoint.vue'),
    meta: {
      action: 'read',
      resource: 'weaknesses',
    },
  },
  {
    path: '/verfication-code',
    name: 'verfication-code',
    component: () => import('@/views/pages/authentication/VerficationCode.vue'),
    meta: {
      layout: 'full',
    },

  },
  // SERVICE
  {
    path: '/apps/add-beneficiary/:id',
    name: 'services',
    component: () => import('@/views/apps/add-beneficiary/family-file/services.vue'),
    meta: {
      action: 'read',
      resource: 'services',
    },
  },
  {
    path: '/apps/add-beneficiary/:id',
    name: 'family-card',
    component: () => import('@/views/apps/add-beneficiary/family-file/family-card.vue'),
    meta: {
      action: 'read',
      resource: 'beneficiaries',
    },
  },
  // {
  //   path: '/apps/education-tracking',
  //   name: 'education-tracking',
  //   component: () => import('@/views/apps/education-tracking/education-tracking.vue'),
  // },
  // problem
  // *===============================================---*
  // *--------- candidates ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/candidates/list',
    name: 'apps-candidates-list',
    component: () => import('@/views/apps/candidates/candidates-list/candidatesList.vue'),
    meta: {
      action: 'read',
      resource: 'candidates',
    },
  },
  {
    path: '/apps/candidates/:id',
    name: 'candidate-edit',
    component: () => import('@/views/apps/candidates/candidates-list/EditCandidate.vue'),
    meta: {
      action: 'read',
      resource: 'candidates',
    },
  },
  // {
  //   path: '/apps/candidates/view/:id',
  //   name: 'apps-candidates-view',
  //   component: () => import('@/views/apps/candidates/candidates-view/candidatesView.vue'),
  // },
  // {
  //   path: '/apps/candidates/edit/:id',
  //   name: 'apps-candidates-edit',
  //   component: () => import('@/views/apps/candidates/candidates-edit/candidatesEdit.vue'),
  // },
  {
    path: '/apps/complains/update-complains/:id',
    name: 'update-complains',
    component: () => import('@/views/apps/problems/problems-list/EditBeneficiaryProblem.vue'),
    meta: {
      action: 'all',
      resource: 'complaints',
    },
  },
  {
    path: '/apps/complains/reply-complains/:id',
    name: 'reply-complains',
    component: () => import('@/views/apps/problems/problems-list/replyProblem.vue'),
    meta: {
      action: 'all',
      resource: 'complaints',
    },
  },
  {
    path: '/apps/complains/add-complain',
    name: 'add-complain-benficiary',
    component: () => import('@/views/apps/problems/problems-list/AddBeneficiaryProblem.vue'),
    meta: {
      action: 'all',
      resource: 'complaints',
    },
  },
  {
    path: '/apps/problem/problems-list',
    name: 'problems-list',
    component: () => import('@/views/apps/problems/problems-list/problems-list.vue'),
    meta: {
      action: 'all',
      resource: 'complaints',
    },
  },

  //////////////CONVERSATIONS//////////////////////
  {
    path: '/apps/conversations/list',
    name: 'conversations-list',
    component: () => import('@/views/apps/conversations/list.vue'),
    meta: {
      resource: 'conversation',
      action: 'all',
    },
  },
  {
    path: '/apps/conversations/reply-conversations/:id',
    name: 'reply-conversations',
    component: () => import('@/views/apps/conversations/replyconversations.vue'),
    meta: {
      resource: 'conversation',
      action: 'all',
    },
  },
  {
    path: '/apps/conversations/add-conversations',
    name: 'add-conversations',
    component: () => import('@/views/apps/conversations/add-conversations.vue'),
    meta: {
      resource: 'conversation',
      action: 'all',
    },
  },
 

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/apps/users-attendence/list',
    name: 'apps-users-attendence-list',
    component: () => import('@/views/apps/attendeceUser/userList.vue'),
    meta: {
      action: 'read',
      resource: 'users',
    },
  },
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      action: 'read',
      resource: 'users',
    },
  },
  // *===============================================---*
  // *--------- requestAuditing ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/apps/services/request-auditing',
    name: 'request-auditing',
    component: () => import('@/views/apps/services-beneficiary-list/request-auditing.vue'),
    meta: {
      resource: 'services',
      action: 'read',
    },
  },
  {
    path: '/apps/case-requests',
    name: 'case-auditing',
    component: () => import('@/views/apps/case-requests/index.vue'),
    meta: {
      resource: 'services',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- leaves-approve ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/apps/leaves/leaves-approve',
    name: 'leaves-approve',
    component: () => import('@/views/apps/leaves/leaves-approve.vue'),
    meta: {
      action: 'read',
      resource: 'all',
    },
  },
  {
    path: '/apps/leaves/leaves-approve-coordinator',
    name: 'leaves-approve-coordinator',
    component: () => import('@/views/apps/leaves-approve/List.vue'),
    meta: {
      resource: 'leaves_approve_coordinator',
      action: 'read',
    },
  },
  /// ////////////////////salary/////////////////

  {
    path: '/apps/salary',
    name: 'salary',
    component: () => import('@/views/apps/salary/add-salary.vue'),
    meta: {
      action: 'all',
      resource: 'salaries',
    },
  },
  {
    path: '/apps/salary/:id',
    name: 'edit-salary',
    component: () => import('@/views/apps/salary/edit-salary.vue'),
    meta: {
      action: 'all',
      resource: 'salaries',
    },

  },
  /// ////////////////////Out In come /////////////////
  {
    path: '/apps/outcome',
    name: 'outcome',
    component: () => import('@/views/apps/OutCome/add-outcome.vue'),
    meta: {
      action: 'all',
      resource: 'out_put',
    },
  },
  {
    path: '/apps/edit-outcome:id',
    name: 'edit-outcome',
    component: () => import('@/views/apps/OutCome/edit-outcome.vue'),
    meta: {
      action: 'all',
      resource: 'out_put',
    },
  },
  {
    path: '/apps/income',
    name: 'income',
    component: () => import('@/views/apps/INCome/add-income.vue'),
    meta: {
      action: 'all',
      resource: 'out_come',
    },
  },
  {
    path: '/apps/edit-income:id',
    name: 'edit-income',
    component: () => import('@/views/apps/INCome/edit-income.vue'),
    meta: {
      action: 'all',
      resource: 'out_come',
    },
  },
  {
    path: '/apps/show-inform/:id',
    name: 'show-inform',
    component: () => import('@/views/dashboard/ecommerce/show-inform.vue'),
    meta: {
      action: 'read',
      resource: 'inform',
    },
  },
  /// ////////////////////ORV /////////////////
  {
    path: '/apps/store-visit',
    name: 'store-visit',
    component: () => import('@/views/ORV/StoreVisit.vue'),
    meta: {
      action: 'all',
      resource: 'store_visit',
    },
  },
  {
    path: '/apps/orv-stats',
    name: 'orv-stats',
    component: () => import('@/views/ORV/stats.vue'),
    meta: {
      action: 'all',
      resource: 'store_visit',
    },
  },
  {
    path: '/apps/orv-additional-request',
    name: 'orv-additional-request',
    component: () => import('@/views/ORV/required-modifications/additionList.vue'),
    meta: {
      action: 'all',
      resource: 'orv-additional-request',
    },
  },
  {
    path: '/apps/store-family-visit',
    name: 'store-family-visit',
    component: () => import('@/views/ORV/storeFmailyOrv.vue'),
    meta: {
      action: 'all',
      resource: 'store_visit',
    },
  },
  {
    path: '/apps/list-visit',
    name: 'list-visit',
    component: () => import('@/views/ORV/orvList.vue'),
    meta: {
      action: 'all',
      resource: 'store_visit',
    },
  },
  {
    path: '/apps/show-visit/:id',
    name: 'show-orv-visit',
    component: () => import('@/views/ORV/showOrv.vue'),
    meta: {
      action: 'all',
      resource: 'store_visit',
    },
  },
  /// ////////////////////DONOR /////////////////
  {
    path: '/apps/donor',
    name: 'donor',
    component: () => import('@/views/apps/donor/donorList.vue'),
    meta: {
      action: 'all',
      resource: 'donor',
    },
  },
  {
    path: '/apps/donor/:id',
    name: 'edit-donor',
    component: () => import('@/views/apps/donor/edit-donor.vue'),
    meta: {
      action: 'all',
      resource: 'donor',
    },
  },
  /// ////////////////////UNITS /////////////////
  {
    path: '/apps/unit',
    name: 'unit',
    component: () => import('@/views/apps/Units/add-unit.vue'),
    meta: {
      action: 'read',
      resource: 'unit',
    },
  },
  {
    path: '/apps/unit/:id',
    name: 'edit-unit',
    component: () => import('@/views/apps/Units/edit-unit.vue'),
    meta: {
      action: 'read',
      resource: 'unit',
    },
  },
  /// ////////////////////warehouse /////////////////
  {
    path: '/apps/warehouse',
    name: 'warehouse',
    component: () => import('@/views/apps/warehouse/add-warehouse.vue'),
    meta: {
      action: 'all',
      resource: 'warehouse',
    },
  },
  {
    path: '/apps/warehouse/:id',
    name: 'edit-warehouse',
    component: () => import('@/views/apps/warehouse/edit-warehouse.vue'),
    meta: {
      action: 'all',
      resource: 'warehouse',
    },
  },
  /// ////////////////////currency /////////////////
  {
    path: '/apps/currency',
    name: 'currency',
    component: () => import('@/views/apps/currency/add-currency.vue'),
    meta: {
      action: 'read',
      resource: 'currency',
    },
  },
  {
    path: '/apps/currency/:id',
    name: 'edit-currency',
    component: () => import('@/views/apps/currency/edit-currency.vue'),
    meta: {
      action: 'read',
      resource: 'currency',
    },
  },
  /// ////////////////////VENDOR /////////////////
  {
    path: '/apps/vendor',
    name: 'vendor',
    component: () => import('@/views/apps/vendor/vendorList.vue'),
    meta: {
      action: 'all',
      resource: 'vendor',
    },
  },
  {
    path: '/apps/vendor/:id',
    name: 'edit-vendor',
    component: () => import('@/views/apps/vendor/edit-vendor.vue'),
    meta: {
      action: 'all',
      resource: 'vendor',
    },
  },

  {
    path: '/apps/donor-biller',
    name: 'donor-biller',
    component: () => import('@/views/apps/Biller/add-biller.vue'),
    meta: {
      action: 'all',
      resource: 'donor',
    },
  },
  {
    path: '/apps/donor-biller/:id',
    name: 'edit-donor-biller',
    component: () => import('@/views/apps/Biller/edit-biller.vue'),
    meta: {
      action: 'all',
      resource: 'donor',
    },
  },
  {
    path: '/apps/donor-account',
    name: 'donor-account',
    component: () => import('@/views/apps/Account/add-account.vue'),
    meta: {
      action: 'all',
      resource: 'donor_account',
    },
  },
  {
    path: '/apps/donor-account/:id',
    name: 'edit-donor-account',
    component: () => import('@/views/apps/Account/edit-account.vue'),
    meta: {
      action: 'all',
      resource: 'donor_account',
    },
  },
  {
    path: '/apps/donor-sub-account',
    name: 'donor-sub-account',
    component: () => import('@/views/apps/Account/SubAccount/add-sub-account.vue'),
    meta: {
      action: 'all',
      resource: 'donor_sub_account',
    },
  },
  {
    path: '/apps/donor-sub-account/:id',
    name: 'edit-sub-donor-account',
    component: () => import('@/views/apps/Account/SubAccount/edit-sub-account.vue'),
    meta: {
      action: 'all',
      resource: 'donor_sub_account',
    },
  },
  /// ////////////////////FINANCE /////////////////
  {
    path: '/apps/new-item',
    name: 'new-item',
    component: () => import('@/views/apps/finance/cretaItem.vue'),
    meta: {
      action: 'read',
      resource: 'users',
    },
  },
  {
    path: '/apps/new-payment-order',
    name: 'create-payment-order',
    component: () => import('@/views/apps/finance/CreatePayemnetOrder.vue'),
    meta: {
      action: 'all',
      resource: 'payment_order',
    },
  },
  {
    path: '/apps/new-release-order',
    name: 'new-release-order',
    component: () => import('@/views/apps/finance/create-new-release.vue'),
    meta: {
      action: 'all',
      resource: 'warehouse_transactions',
    },
  },
  {
    path: '/apps/journal-transaction',
    name: 'create-report-de',
    component: () => import('@/views/apps/finance/reportsDe.vue'),
    meta: {
      action: 'all',
      resource: 'journal_transaction',
    },
  },
  {
    path: '/apps/requestItemList',
    name: 'request-ItemList',
    component: () => import('@/views/apps/finance/reuestItemList.vue'),
    meta: {
      action: 'all',
      resource: 'approve_items_order',
    },
  },
  {
    path: '/apps/rejected-item',
    name: 'rejected-ItemList',
    component: () => import('@/views/apps/finance/rejectedItemList.vue'),
    meta: {
      action: 'all',
      resource: 'rejected_item',
    },
  },
  {
    path: '/apps/request-item',
    name: 'request-item',
    component: () => import('@/views/apps/finance/requestItem.vue'),
    meta: {
      action: 'all',
      resource: 'items_order',
    },
  },
  {
    path: '/apps/warehouse-request-item/:id',
    name: 'warehouse-request-item',
    component: () => import('@/views/apps/finance/WarehouseRequestItem.vue'),
    meta: {
      action: 'all',
      resource: 'approve_items_order',
    },
  },
  {
    path: '/apps/update-request-item/:id',
    name: 'update-request-item',
    component: () => import('@/views/apps/finance/updateRequestItem.vue'),
    meta: {
      action: 'all',
      resource: 'items_order',
    },
  },
  {
    path: '/apps/read-request-item/:id',
    name: 'read-request-item',
    component: () => import('@/views/apps/finance/showRequestItem.vue'),
    meta: {
      action: 'all',
      resource: 'items_order',
    },
  },
  {
    path: '/apps/request-price-offer',
    name: 'request-price-offer',
    component: () => import('@/views/apps/finance/Priceoffer.vue'),
    meta: {
      action: 'all',
      resource: 'upload_price_offers-all',
    },
  },
  {
    path: '/apps/item-details-warehouse',
    name: 'item-details-warehouse',
    component: () => import('@/views/apps/finance/itemDeatilsWarehouse.vue'),
    meta: {
      action: 'all',
      resource: 'upload_price_offers-all',
    },
  },
  {
    path: '/apps/upload-invoice',
    name: 'upload-invoice',
    component: () => import('@/views/apps/finance/uploadinvoice.vue'),
    meta: {
      action: 'all',
      resource: 'upload_bill-all',
    },
  },
  {
    path: '/apps/request-purchase-order',
    name: 'request-purchase-order',
    component: () => import('@/views/apps/finance/purchaseOrder.vue'),
    meta: {
      action: 'all',
      resource: 'warehouse_transactions',
    },
  },
  {
    path: '/apps/create-new-purchase-order',
    name: 'create-new-purchase-order',
    component: () => import('@/views/apps/finance/create-new-purches-order.vue'),
    meta: {
      action: 'all',
      resource: 'complete_purches_order',
    },
  },
  {
    path: '/apps/create-purches-order/:id',
    name: 'create-purches-order',
    component: () => import('@/views/apps/finance/create-purches-order.vue'),
    meta: {
      action: 'all',
      resource: 'complete_purches_order',
    },
  },
  {
    path: '/apps/create-release-order/:id',
    name: 'create-release-order',
    component: () => import('@/views/apps/finance/Create-release-order.vue'),
    meta: {
      action: 'all',
      resource: 'approve_items_order',
    },
  },
  {
    path: '/apps/export-item',
    name: 'export-item',
    component: () => import('@/views/apps/finance/exportItem.vue'),
    meta: {
      action: 'all',
      resource: 'export-item',
    },
  },
  {
    path: '/apps/item-details',
    name: 'item-details',
    component: () => import('@/views/apps/finance/itemDetails.vue'),
    meta: {
      action: 'all',
      resource: 'item_details',
    },
  },
  {
    path: '/apps/enter-item',
    name: 'enter-item',
    component: () => import('@/views/apps/finance/importItem.vue'),
    meta: {
      action: 'all',
      resource: 'enter_item',
    },
  },
  /// ////////////////////ITEM /////////////////
  {
    path: '/apps/item',
    name: 'item',
    component: () => import('@/views/apps/item/add-item.vue'),
    meta: {
      action: 'all',
      resource: 'item',
    },
  },
  {
    path: '/apps/item/:id',
    name: 'edit-item',
    component: () => import('@/views/apps/item/edit-item.vue'),
    meta: {
      action: 'read',
      resource: 'item',
    },
  },
  /// ////////////////////INFORM /////////////////
  {
    path: '/apps/add-inform',
    name: 'add-inform',
    component: () => import('@/views/apps/inform/add-inform.vue'),
    meta: {
      action: 'read',
      resource: 'inform',
    },
  },
  {
    path: '/apps/edit-inform/:id',
    name: 'edit-inform',
    component: () => import('@/views/apps/inform/edit-inform.vue'),
  // meta:{
  //   action:'read',
  //   resource:'inform'
  //       }
  },

  {
    path: '/apps/item-category',
    name: 'item-category',
    component: () => import('@/views/apps/item-category/add-itemCategory.vue'),
    meta: {
      action: 'all',
      resource: 'item_category',
    },
  },
  {
    path: '/apps/item-category/:id',
    name: 'edit-item-category',
    component: () => import('@/views/apps/item-category/edit-itemCategory.vue'),
    meta: {
      action: 'all',
      resource: 'item_category',
    },
  },
  /// ////////////////////EXCHANGE RATE/////////////////

  {
    path: '/apps/exchange-rate',
    name: 'exchange-rate',
    component: () => import('@/views/apps/exchangeRate/add-exchange-rate.vue'),
    meta: {
      action: 'all',
      resource: 'exchange_rate',
    },
  },
  {
    path: '/apps/exchange-rate/:id',
    name: 'edit-exchange-rate',
    component: () => import('@/views/apps/exchangeRate/edit-exchange-rate.vue'),
    meta: {
      action: 'all',
      resource: 'exchange_rate',
    },
  },
  /// ////////////////////Bill /////////////////

  {
    path: '/apps/purchase-bill',
    name: 'purchase-bill',
    component: () => import('@/views/apps/PurchesBill/add-purchase-bill.vue'),
    meta: {
      action: 'all',
      resource: 'upload_bill',
    },
  },
  {
    path: '/apps/purchase-bill/:id',
    name: 'edit-purchase-bill',
    component: () => import('@/views/apps/PurchesBill/edit-purchase-bill.vue'),
    meta: {
      action: 'all',
      resource: 'upload_bill',
    },
  },
  {
    path: '/apps/purchase-bill/:id',
    name: 'show-purchase-bill',
    component: () => import('@/views/apps/PurchesBill/show-purchase-bill.vue'),
    meta: {
      action: 'all',
      resource: 'upload_bill',
    },
  },
  /// ////////////////////PRICE OFFER /////////////////

  {
    path: '/apps/price-offer',
    name: 'price-offer',
    component: () => import('@/views/apps/PriceOffer/add-price-offer.vue'),
    meta: {
      action: 'all',
      resource: 'upload_price_offers',
    },
  },
  {
    path: '/apps/price-offer/:id',
    name: 'edit-price-offer',
    component: () => import('@/views/apps/PriceOffer/edit-price-offer.vue'),
    meta: {
      action: 'all',
      resource: 'upload_price_offers',
    },
  },
  /// ////////////////////PURCHES ORDER /////////////////

  {
    path: '/apps/purchase-order',
    name: 'purchase-order',
    component: () => import('@/views/apps/purschesOrder/add-purchase-order.vue'),
    meta: {
      action: 'all',
      resource: 'purchase_order',
    },
  },
  {
    path: '/apps/purchase-order/:id',
    name: 'edit-purchase-order',
    component: () => import('@/views/apps/purschesOrder/edit-purchase-order.vue'),
    meta: {
      action: 'all',
      resource: 'purchase_order',
    },
  },
  /// ////////////////////PURCHES CONTARCE /////////////////

  {
    path: '/apps/purchase-contract',
    name: 'purchase-contract',
    component: () => import('@/views/apps/purchesContract/add-purchase-contract.vue'),
    meta: {
      action: 'all',
      resource: 'purchase_contract',
    },
  },
  {
    path: '/apps/purchase-contract/:id',
    name: 'edit-purchase-contract',
    component: () => import('@/views/apps/purchesContract/edit-purchase-contract.vue'),
    meta: {
      action: 'all',
      resource: 'purchase_contract',
    },
  },
  /// ////////////////////reward/////////////////

  {
    path: '/apps/reward',
    name: 'reward',
    component: () => import('@/views/apps/rewards/add-reward.vue'),
    meta: {
      action: 'all',
      resource: 'rewards',
    },
  },
  {
    path: '/apps/reward/:id',
    name: 'edit-reward',
    component: () => import('@/views/apps/rewards/edit-reward.vue'),
    meta: {
      action: 'all',
      resource: 'rewards',
    },
  },
  {
    path: '/apps/user-reward',
    name: 'user-reward',
    component: () => import('@/views/apps/reward-user/add-user-reward.vue'),
    meta: {
      action: 'all',
      resource: 'rewards',
    },
  },
  {
    path: '/apps/edit-user-reward/:id',
    name: 'edit-user-reward',
    component: () => import('@/views/apps/reward-user/edit-user-reward.vue'),
    meta: {
      action: 'all',
      resource: 'rewards',
    },
  },


  {
    path: '/apps/add-appointment',
    name: 'add-appointment',
    component: () => import('@/views/apps/appointment/addAppointment.vue'),
    meta: {
      action: 'all',
      resource: 'appointment',
    },
  },
  {
    path: '/apps/edit-appointment/:id',
    name: 'edit-appointment',
    component: () => import('@/views/apps/appointment/editAppointment.vue'),
    meta: {
      action: 'all',
      resource: 'appointment',
    },
  },
  /// ///////////PAYMENT///////////////
  {
    path: '/apps/user-payment',
    name: 'user-payment',
    component: () => import('@/views/apps/user-payment/add-user-payment.vue'),
    meta: {
      action: 'all',
      resource: 'user_payment',
    },
  },
  {
    path: '/apps/edit-user-payment/:id',
    name: 'edit-user-payment',
    component: () => import('@/views/apps/user-payment/edit-user-payment.vue'),
    meta: {
      action: 'all',
      resource: 'user_payment',
    },
  },
  {
    path: '/apps/users/user-profile',
    name: 'user-profile',
    component: () => import('@/views/apps/user/user-profile/Profile.vue'),
    meta: {
      action: 'read',
      resource: 'users',
    },
  },
  {
    path: '/apps/users/change-password',
    name: 'change-password',
    component: () => import('@/views/apps/user/user-profile/ChangePassword.vue'),
     meta: {
      action: 'all',
      resource: 'password_expired',
    },
  },
  {
    path: '/apps/users/add',
    name: 'users-add',
    component: () => import('@/views/apps/user/users-add/UsersAdd.vue'),
    meta: {
      action: 'read',
      resource: 'users',
    },
  },
  {
    path: '/apps/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
    meta: {
      action: 'read',
      resource: 'users',
    },
  },

  // *===============================================---*
  // *--------- Holidays -------------------------------*
  // *===============================================---*
  {
    path: '/apps/holidays',
    name: 'holidays',
    component: () => import('@/views/apps/holidays/Holidays.vue'),
    meta: {
      action: 'all',
      resource: 'holidays',
    },

  },
  {
    path: '/apps/leaves',
    name: 'leaves',
    component: () => import('@/views/apps/leaves/Holidays.vue'),
    meta: {
      action: 'read',
      resource: 'all',
    },

  },
  {
    path: '/apps/report-service',
    name: 'generate-service-report',
    component: () => import('@/views/apps/GenerateServiceReport.vue'),
    meta: {
      resource: 'modification_requests',
      action: 'read',
    },
  },
  {
    path: '/apps/leaves-type',
    name: 'leaves-type',
    component: () => import('@/views/apps/leaveType/add-leaveType.vue'),
    meta: {
      action: 'all',
      resource: 'leaves_type',
    },

  },
  // *===============================================---*
  // *--------- Attendance -------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/attendance',
  //   name: 'Attendance',
  //   component: () => import('@/views/apps/attendance/AttendanceList.vue'),

  // },
  // {
  //   path: '/apps/leave-type',
  //   name: 'Attendance',
  //   component: () => import('@/views/apps/attendance/leave-Type/LeaveList.vue'),

  // },
  // {
  //   path: '/apps/leave-Application',
  //   name: 'Leave-Application',
  //   component: () => import('@/views/apps/attendance/leave-application/LeaveAppList.vue'),

  // },

  // {
  //   path: '/apps/email/:folder',
  //   name: 'apps-email-folder',
  //   component: () => import('@/views/apps/email/Email.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'email-application',
  //     navActiveLink: 'apps-email',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },
  // {
  //   path: '/apps/email/label/:label',
  //   name: 'apps-email-label',
  //   component: () => import('@/views/apps/email/Email.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'email-application',
  //     navActiveLink: 'apps-email',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },

  // // *===============================================---*
  // // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // // *===============================================---*
  // {
  //   path: '/apps/todo',
  //   name: 'apps-todo',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //   },
  // },
  // {
  //   path: '/apps/todo/:filter',
  //   name: 'apps-todo-filter',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //     navActiveLink: 'apps-todo',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },
  // {
  //   path: '/apps/todo/tag/:tag',
  //   name: 'apps-todo-tag',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //     navActiveLink: 'apps-todo',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/chat',
  //   name: 'apps-chat',
  //   component: () => import('@/views/apps/chat/Chat.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'chat-application',
  //   },
  // },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/e-commerce/shop',
  //   name: 'apps-e-commerce-shop',
  //   component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left-detached',
  //     contentClass: 'ecommerce-application',
  //     pageTitle: 'Shop',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Shop',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/e-commerce/wishlist',
  //   name: 'apps-e-commerce-wishlist',
  //   component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
  //   meta: {
  //     pageTitle: 'Wishlist',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Wishlist',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/e-commerce/checkout',
  //   name: 'apps-e-commerce-checkout',
  //   component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
  //   meta: {
  //     pageTitle: 'Checkout',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Checkout',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/e-commerce/:slug',
  //   name: 'apps-e-commerce-product-details',
  //   component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
  //   meta: {
  //     pageTitle: 'Product Details',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Shop',
  //         active: true,
  //       },
  //       {
  //         text: 'Product Details',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/users/list',
  //   name: 'apps-users-list',
  //   component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  // },
  // {
  //   path: '/apps/users/view/:id',
  //   name: 'apps-users-view',
  //   component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  // },
  // {
  //   path: '/apps/users/edit/:id',
  //   name: 'apps-users-edit',
  //   component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  // },

  // Invoice
  // {
  //   path: '/apps/invoice/list',
  //   name: 'apps-invoice-list',
  //   component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  // },
  // {
  //   path: '/apps/invoice/preview/:id',
  //   name: 'apps-invoice-preview',
  //   component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  // },
  // {
  //   path: '/apps/invoice/add/',
  //   name: 'apps-invoice-add',
  //   component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  // },
  // {
  //   path: '/apps/invoice/edit/:id',
  //   name: 'apps-invoice-edit',
  //   component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  // },
]
